import React, { Fragment, useState, useEffect, useReducer,  useRef, useCallback} from 'react';
import { useNavigate } from 'react-router-dom';
import { localStorageUtil,currentLang } from '../../../utils/functions';
import { getSubscriptionPaths } from '../../../utils/functions';
import Image from '../../../components/common/Image';
import {useTranslation} from 'react-i18next';
import W from '../../../components/common/Word';
import SubscriptionCustomizeLoader from '../../../assets/contentLoader/SubscriptionCustomizeLoader';
import organicFarmersImg from '../../../assets/img/subscription/organic_farmers.jpg';
import flatstore from 'flatstore';
import {
  getSubscriptionProductList
} from "../../../utils/services";
import {isNil} from 'lodash';

const calculateTotalItems = (items) => Object.values(items).reduce((total, item) => total + item.quantity, 0);

const itemSelectionReducer = (state, action) => {
  let newState = { ...state };
  switch (action.type) {
    case 'add':
      // Ensure not to exceed maxItems
      if (newState.totalItems >= newState.maxItems) return newState;
      const quantityAdd = newState.items[action.itemId] ? newState.items[action.itemId].quantity + 1 : 1;
      newState.items[action.itemId] = { product_id: action.productId, quantity: quantityAdd };
      break;
    case 'remove':
      if (newState.items[action.itemId] && newState.items[action.itemId].quantity > 1) {
        newState.items[action.itemId].quantity -= 1;
      } else if (newState.items[action.itemId]) {
        delete newState.items[action.itemId];
      }
      break;
    case 'reset':
      newState.items = {};
      break;
    case 'updateMaxItems':
      newState.maxItems = action.maxItems;
      break;
    default:
      return newState;
  }
  newState.totalItems = calculateTotalItems(newState.items);
  return newState;
};

function CustomizeSubscription({subscription}) {

  const [data, setData] = useState([]);
  const [submissionNotice, setSubmissionNotice] = useState({ message: '', className: '' });
  const [maxQtyMsgVisible, setMaxQtyMsgVisible] = useState({});

  const { t } = useTranslation();
  const navigate = useNavigate();
  const paths = getSubscriptionPaths();
  const [loading, setLoading] = useState(false); // State to track loading state

  const size = localStorageUtil.getItem('subscription')?.size || '';
  const period = localStorageUtil.getItem('subscription')?.period || '';
  const type = localStorageUtil.getItem('subscription')?.type || 'customize';

  const [maxItemsReached, setMaxItemsReached] = useState(false);

  const [maxItemsLimit, setMaxItemsLimit] = useState( size === 'small' ? 11 : 20 );

  const [selectedItems, dispatch] = useReducer(itemSelectionReducer, {
    items: localStorageUtil.getItem('subscription')?.items || {},
    totalItems: 0,
    maxItems: maxItemsLimit,
  }, initState => ({ ...initState, totalItems: calculateTotalItems(initState.items) }));


  useEffect(() => {
    if( ! type ){
      navigate(paths.base || '/');
    } else if( ! size || ! period ){
      navigate(paths.steps['size'] || '/');
    }

    localStorageUtil.updateObjectItem('subscription', { items: selectedItems.items });
    // Update maxItemsReached based on totalItems and maxItems

    if( selectedItems.totalItems >= selectedItems.maxItems ){
      setMaxItemsReached(true);
    } else {
      setMaxItemsReached(false);
    }

  }, [selectedItems]);


  useEffect(() => {
    if (isNil(subscription)) {
      getSubscriptionProductList();
    }
    if (subscription && !isNil(subscription)) {
      const filteredData = subscription.filter(item => {
        return item.subscription_type === 'grutto_subscription_custom_' + size;
      });
      setData(filteredData);

      if( typeof filteredData[0].bundle_size !== "undefined" ){
        let maxItemsNum = Number(filteredData[0].bundle_size);
        setMaxItemsLimit(maxItemsNum);
        dispatch({ type: 'updateMaxItems', maxItems: maxItemsNum });
      }
    }
  }, [size, subscription]);


  // hide submissionNotice after certain time
  useEffect(() => {
      let timeout;
      if (submissionNotice) {
          timeout = setTimeout(() => {
              setSubmissionNotice({ message: '', className: '' });
          }, 3000);
      }
      return () => clearTimeout(timeout);
  }, [submissionNotice, setSubmissionNotice]);


  useEffect(() => {
    if (data.length > 0) {
      // Data is available, set up the references
      assignRefs();
    }
  }, [data]);

  const animalTypes = [...new Set(data.map(item => item.animal_type))];
  const animalGroupRefs = useRef({});
  const assignRefs = () => {
    // Assign refs for each animal group element
    animalTypes.forEach(type => {
      animalGroupRefs.current[type] = React.createRef();
    });
  };

  useEffect(() => {
    // Flag to check if all selected items exist in data
    let allItemsExist = true;

    if( data.length ){
      // Iterate over each selectedItem to check if it exists in data based on bundled_item_id
      Object.keys(selectedItems.items).forEach(itemKey => {
        const existsInData = data.some(dataItem => dataItem.bundled_item_id.toString() === itemKey);
        if (!existsInData) {
          allItemsExist = false;
        }
      });
    }

    if ( data.length && (  selectedItems.totalItems > maxItemsLimit || !allItemsExist ) ) {
      dispatch({ type: 'reset' });
    }
  }, [selectedItems, data]);

  const updateMaxQtyMsgVisibility = (id, isVisible) => {
    setMaxQtyMsgVisible(prev => ({
      ...prev,
      [id]: isVisible
    }));
    if (isVisible) {
      setTimeout(() => {
        setMaxQtyMsgVisible(prev => ({
          ...prev,
          [id]: false
        }));
      }, 3000);
    }
  };

  const handleItemSelection = useCallback((itemId, productId, maxQty, actionType) => {

    if (actionType === 'add') {
      const currentItemQuantity = selectedItems.items[itemId]?.quantity ?? 0;
      if (currentItemQuantity >= parseInt(maxQty)) {
        updateMaxQtyMsgVisibility(itemId, true); // Show max quantity message
        return;
      }
    }
    // Otherwise, proceed with item selection
    if (actionType === 'add' && selectedItems.totalItems >= selectedItems.maxItems) {
      setMaxItemsReached(true);
      setSubmissionNotice({ message: <W>subscription.response.success</W>, className: 'success' });
      return; // Early return to prevent adding more items
    }
    dispatch({ type: actionType, itemId, productId });

  }, [selectedItems, maxQtyMsgVisible]); // Add all dependencies here

  /**
   * Handle submitted items
   */
  const handleSubmit = () => {
    if (maxItemsReached) {
      // If not loading, proceed with the AJAX request
      if (!loading) {
          setLoading(true); // Set loading state to true
          flatstore.set('basket_status', true);

          const formData = new FormData();
          formData.append('type', type);
          formData.append('size', size);
          formData.append('period', period);
          formData.append('curated', '');
          formData.append('items', JSON.stringify(selectedItems.items));

          fetch(`/${currentLang()}/shop/ajaxflow/add-subscription-to-cart`, {
              method: 'POST',
              body: formData
          })
          .then(response => response.json())
          .then(data => {
              if (data.success && data.data.cart_data) {
                  flatstore.set('basket_count', data.data.cart_data);
                  flatstore.set('basket_status', false);
                  // reset items
                  localStorageUtil.updateObjectItem('subscription', { items: {} });
                  window.location.href = "/" + currentLang() + "/shop/";
              } else {
                  setLoading(false);
                  setSubmissionNotice({ message: data.data.message, className: 'error' });
                  flatstore.set('basket_status', false);
              }
          })
          .catch(error => {
              setLoading(false);
              setSubmissionNotice({ message: 'Error adding bundle to cart', className: 'error' });
              console.error('Error adding bundle to cart:', error);
          });
      }
    } else {
      setSubmissionNotice({ message: `Please add ${selectedItems.maxItems - selectedItems.totalItems} more item(s) to proceed.`, className: 'error' });
    }
  };

  // Box Precentage
  const fillPercentage = (selectedItems.totalItems / selectedItems.maxItems) * 100;
  const boxFill = {
    height: `${Math.min(fillPercentage, 100)}%`, // Ensure it doesn't exceed 100%
  };

  // Progress bar calculation based on item selection
  const progressBarPercentage = 50 + ((selectedItems.totalItems / selectedItems.maxItems) * 50); // Increase from 50% to 75%
  const progressBarWidth = {
    width: `${progressBarPercentage}%`,
  };

  if (!data.length) {
    return (
      <div className='subscription-box'>
        <div className="container pt-5 pb-5">
          <div className="row">
            <div className="col-12">
              <SubscriptionCustomizeLoader />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Fragment>
    <div className='subscription-box'>

      <div className='progress-bar'>
          <div className='fill' style={progressBarWidth}></div>
      </div>

      {submissionNotice.message && (
        <div className={`response ${submissionNotice.className}`}>
          {submissionNotice.message}
        </div>
      )}

      <div className="container">
        <div className="mt-4 text-center">
          <h2 className="heading"> {t('subscription.customize.page.title', {num: selectedItems.maxItems})}</h2>
        </div>
      </div>


      <div className='container farmshop-items'>
        <div className='row'>

          <div className='pack-overview'>
            <div className='subscription__cta customizeCta loading'>
              <div className="title"><W>subscription.overview.title</W></div>
              <div className="count">
                  <span className="ico"><span className="ico-fill" style={boxFill}></span></span>
                  <span className="numbers">{`${selectedItems.totalItems}` + " " + t('subscription.of') + " " + `${selectedItems.maxItems}`}</span>
                  <span className='ml-1 d-none d-sm-block'><W>subscription.products</W></span>
              </div>
              {loading ? (
                <button className="btn btn--loading"><span className='ico'></span></button>
              ) : maxItemsReached ? (
                <button className="btn btn--red" onClick={handleSubmit}><W>subscription.continue_delivery</W></button>
              ) : (
                <button className="btn btn--disable"> <W>subscription.continue_delivery</W> </button>
              )}
            </div>
          </div>

          <div className='col'>
          <div className='bundle__items'>

            {/* Render animal groups and their items */}
            {animalTypes.map(animalType => (
                <Fragment key={animalType}>
                  <div
                    className={`animal_group animal_group_${animalType}`}>
                    <a name={`products_${animalType}`}></a>
                    <h2 className="title m-0">
                      <span className="name">{t(animalType).charAt(0).toUpperCase() + t(animalType).slice(1)}</span><span className="sep">-</span><span className="desc"> {t((animalType) + '_desc')}</span>
                    </h2>
                    <hr ref={animalGroupRefs.current[animalType]} id={`animal_type_${animalType}`} />
                  </div>

                  {/* Render items for the current animal type */}
                  {data
                    .filter(item => item.animal_type === animalType)
                    .map(item => (
                      <div className={"farmshop__item "+(item.is_organic == 1 ? 'organic' : '')} key={item.bundled_item_id}>
                        <div className="farmshop__title">
                          <span className="title">{item.title}</span>
                        </div>
                        <figure className="img m-0">
                          {maxQtyMsgVisible[item.bundled_item_id] ?
                            <>
                              <div className='msg'>
                                {t('subscription.response.maximum_quantity')}
                              </div>
                            </>
                            :
                            ''
                          }
                          <Image
                            src={item.image_url}
                            alt={item.title}
                            title={item.title}
                            className="img-fluid"
                          />
                        </figure>
                        <div className="farmshop__item__farmer px-2">
                          <div className="farmshop_farmer_image">
                            <img src={organicFarmersImg} width="100" height="100" alt={t('subscription.farmer_name')} title={t('subscription.farmer_name')} />
                          </div>
                          <div className="farmshop_farmer_name">
                            <W>farmers.{item.animal_type}</W>: <W>subscription.farmer_name</W>
                          </div>
                        </div>
                        <div className='body p-2'>
                          <div className="subscription__item__info">
                            {(item.subscription_amount ? item.subscription_amount * item.pieces_per_vac : item.pieces_per_vac) * (item.weight)}<W>farmshop.unit_txt.{item.unit_type ? item.unit_type : 'g'}</W>
                            <span className="weight">
                              ({item.subscription_amount ? item.subscription_amount * item.pieces_per_vac : item.pieces_per_vac} x {item.weight} <W>farmshop.unit_txt.{item.unit_type ? item.unit_type : 'g'}</W>)
                            </span>
                          </div>
                          <div className={`quantity product-${item.bundled_item_id} ${selectedItems.items[item.bundled_item_id]?.quantity > 0 ? "enabled" : ""}`}>
                          <a className={`plus-btn ${selectedItems.items[item.bundled_item_id]?.quantity >= item.max_quantity && item.max_quantity !== "" ? 'btn--disable' : ''}`} role="button" onClick={() => handleItemSelection(item.bundled_item_id, item.meatcut_product_id, item.max_quantity, 'add')}>
                              <i className='icon-plus'></i>
                            </a>
                            <span className='number'>
                              <input type="text" value={selectedItems.items[item.bundled_item_id]?.quantity.toString() || ''} disabled />
                            </span>
                            <a className="minus-btn" role="button" onClick={() => handleItemSelection(item.bundled_item_id, item.meatcut_product_id, item.max_quantity, 'remove')}>
                              <i className='icon-minus'></i>
                            </a>
                          </div>
                        </div>
                      </div>
                  ))}

                </Fragment>
              ))}

          </div>
          </div>
        </div>
      </div>

    </div>
    </Fragment>
  );
}

export default flatstore.connect(['subscription'])(CustomizeSubscription);