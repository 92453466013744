import React, {useState, useEffect, Fragment} from 'react'
import W from '../../../common/Word';
import flatstore from 'flatstore';
import { currentLang } from '../../../../utils/functions';
import { upperFirst, isArray } from 'lodash';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

const Traceability = ({settings_data, type, data, header=true}) => {

    const [show, setShow] = useState(1);
    const { t } = useTranslation();

    const toggle = () => {
        setShow(show === 0 ? 1 : 0);
    }
    const showTracabilityData = (items, breed=false) => {
        var result = '';
        if(items && items.constructor.name === 'Set'){
            items = Array.from(items);
        }
        if(isArray(items)){
            result = items.map(item=>{ return parse("<span>"+item+"</span>")})
        }else{
            result = (breed) ? breed : items;
        }

        return result;
    }
    return (
        <Fragment>
            <div className='anchor-box'>
                <a name="traceability-box"></a>
            </div>
            <div className={"traceability__box accordion "+((show === 1) ? "" : "open")}>
                {header &&
                    <div className={"accordion__title "+((show === 1) ? "" : "open")} onClick={toggle}>
                        <span>
                            <h2><W>traceability.title</W></h2>
                            <p><W>traceability.desc</W></p>
                        </span>
                        <i className="icon-angle-down traceability__desktop_icon"></i>
                    </div>
                }
                <div className={"accordion__body "+((show === 1) ? "" : "open")}>
                    {header &&
                        <div className="pb-3 body">
                            <W>traceability.body</W>
                        </div>
                    }
                    <ul className="traceability__steps">
                        <li className="">
                            <div className="traceability__content">
                                <span className="icon-earth"></span>
                                <div className="text">
                                    <div className="title">
                                        {t('traceability.birth_country.'+data.shop_brand_slug)}
                                    </div>
                                    <div className="val">
                                    {data.shop_birth_country ? showTracabilityData(data.shop_birth_country) : showTracabilityData(data.product_shop_birth_country)}
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="">
                            <div className="traceability__content">
                                <span className="icon-eartag-o"></span>
                                <div className="text">
                                    <div className="title">
                                        {data.shop_brand_slug === 'wine' ? data.shop_animal_nr_prefix : upperFirst(t('product.earnumber.'+data.shop_brand_slug))}
                                    </div>
                                    <div className="val">
                                        {showTracabilityData(data.shop_animal_nr)}
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="">
                            <div className="traceability__content">
                                <span className="icon-breed"></span>
                                <div className="text">
                                    <div className="title">
                                    {data.shop_brand_slug === 'wine' ? "Druivenras" : <W>traceability.breed</W>}
                                    </div>
                                    <div className="val">
                                        {showTracabilityData(data.breed_title, data.shop_animal_name)}
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="">
                            <div className="traceability__content">
                                <span className="icon-earth"></span>
                                <div className="text">
                                    <div className="title">
                                        {t('traceability.fattening_country.'+data.shop_brand_slug)}
                                    </div>
                                    <div className="val">
                                        {data.shop_fattening_country ? showTracabilityData(data.shop_fattening_country) : showTracabilityData(data.product_shop_fattening_country)}
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="">
                            <div className="traceability__content">
                                <span className="icon-farmers"></span>
                                <div className="text">
                                    <div className="title">
                                        {!['game', 'deer', 'boar', 'roe'].includes(data.shop_brand_slug) &&
                                            <Fragment>
                                                {upperFirst(t('farmers.'+data.shop_brand_slug))}
                                            </Fragment>
                                        }
                                        {['game', 'deer', 'boar', 'roe'].includes(data.shop_brand_slug) &&
                                            <Fragment>
                                                {upperFirst(t('farmers.location'))}
                                            </Fragment>
                                        }
                                    </div>
                                    <div className="val">
                                        {showTracabilityData(data.farmer_title)}
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="">
                            <div className="traceability__content">
                                <span className="icon-butcher"></span>
                                <div className="text">
                                    <div className="title">
                                    {data.shop_brand_slug === 'wine' ? "Domein" :<W>traceability.slaughter_place</W>}
                                    </div>
                                    <div className="val">
                                        {showTracabilityData(data.shop_slaughter_place)}
                                    </div>
                                </div>
                            </div>
                        </li>
                        {data.shop_brand_slug !== 'wine' && (
                        <li className="">
                            <div className="traceability__content">
                                <span className="icon-knife"></span>
                                <div className="text">
                                    <div className="title">
                                        {data.page_slug == 'biologische-bone-broth' || data.page_slug == 'biologische-chicken-broth' ? <W>traceability.created_by</W> : <W>traceability.production_site</W>}
                                    </div>
                                    <div className="val">
                                        {showTracabilityData(data.shop_portioning_place)}
                                    </div>
                                </div>
                            </div>
                        </li>
                        )}
                        <li className="">
                            <div className="traceability__content">
                                <span className="icon-truck"></span>
                                <div className="text">
                                    <div className="title">
                                        <W>traceability.delivered_by</W>
                                    </div>
                                    {settings_data && settings_data[currentLang()+'.delivery_partner'] &&
                                        <div className="val">
                                        {parse(settings_data[currentLang()+'.delivery_partner'])}
                                        </div>
                                    }

                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </Fragment>
    )
}

export default flatstore.connect(['settings_data'])(Traceability);
