// SubscriptionSize.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSubscriptionPaths } from '../../../utils/functions';
import { localStorageUtil } from '../../../utils/functions';
import W from '../../../components/common/Word';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import flatstore from 'flatstore';
import {
  getSubscriptionProductList
} from "../../../utils/services";
import {isNil} from 'lodash';
import Price from '../../../components/product/common/Price';


function SubscriptionSize({subscription}) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const paths = getSubscriptionPaths();
    const type = localStorageUtil.getItem('subscription')?.type || '';

    // Simplified state initialization
    const [size, setSize] = useState(localStorageUtil.getItem('subscription')?.size || 'small');
    const [period, setPeriod] = useState(localStorageUtil.getItem('subscription')?.period || '4_week');

    const [submissionError, setSubmissionError] = useState('');

    const [data, setData] = useState([]);

    useEffect(() => {
        if( ! type ){
            navigate(paths.base || '/');
        }
    }, []);

    useEffect(() => {
        if (isNil(subscription)) {
            getSubscriptionProductList();
        }
        if (subscription && !isNil(subscription)) {
            setData(subscription);
        }
    }, [subscription]);

    const handleSubmit = () => {
        if (size && period) {
            localStorageUtil.updateObjectItem('subscription', { size: size, period: period });
            navigate(paths.steps[type] || '/'); // Fallback to root if path is undefined
            setSubmissionError('');
        } else {
            setSubmissionError('Please select both a size and a delivery period for your subscription.');
        }
    };

    const handleSizeChange = (e) => setSize(e.target.value);
    const handlePeriodChange = (e) => setPeriod(e.target.value);

    // Update progress based on selections
    let progressBarWidthPercentage = 25;
    if (size || period) {
        progressBarWidthPercentage = 37.5;
    }
    if (size && period) {
        progressBarWidthPercentage = 50;
    }

    // ProgressBar
    const progressBarWidth = {
        width: `${progressBarWidthPercentage}%`,
    };

    const getPrice = (amount, unit, sizeOverride) => {

        let price = 0;

        if(!data.length){
            return price;
        }

        // Determine the subscription size to use
        const effectiveSize = sizeOverride || size;

        // Define the subscription type pattern based on the effective type and size
        let subscriptionTypePattern;
        if (type === 'customize') {
            subscriptionTypePattern = new RegExp(`^grutto_subscription_custom_${effectiveSize}$`);
        } else if (type === 'curated') {
            subscriptionTypePattern = new RegExp(`^grutto_subscription_curated_.+_${effectiveSize}$`);
        }

        const subscriptionItem = data.find(item => subscriptionTypePattern.test(item.subscription_type));

        if (subscriptionItem) {
            const bundlePrice = parseFloat(subscriptionItem.bundle_price);

            if(sizeOverride){
                return bundlePrice.toFixed(2)
            }

            let weekCount;
            if (unit === 'week') {
                weekCount = amount;
            } else {
                weekCount = amount * (52/12); // (months) * (52 weeks / 12 months)
            }

            price = (bundlePrice / weekCount).toFixed(2);
        }

        return price;
    };

    return (
        <div className="subscription-box">

            <div className='progress-bar'>
                <div className='fill' style={progressBarWidth}></div>
            </div>

            {submissionError && (
                <div className="response error">
                {submissionError}
                </div>
            )}

            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-md-10'>

                        <div className="container py-4">
                            <div className="row">
                                <div className="col primary-title">
                                    <h2>{t(`subscription.${type}.size-title.title`)}</h2>
                                    <p>{t(`subscription.${type}.size-title.subtitle`)}</p>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                            {['small', 'large'].map((sizeOption, index) => (
                                <div key={index} className="col">
                                <label className="subscription__select" htmlFor={sizeOption}>
                                    <input
                                    type="radio"
                                    id={sizeOption}
                                    name="size"
                                    value={sizeOption}
                                    checked={size === sizeOption}
                                    onChange={handleSizeChange}
                                    />
                                    <div className="checkbox">
                                    <span></span>
                                    </div>
                                    <div className="body">
                                        <div className="title">
                                            <h3 className="heading">{t(`subscription.${type}.${sizeOption}.title`)}</h3>
                                            <p>{t(`subscription.${type}.${sizeOption}.subtitle`)}</p>
                                            <div className="desc">
                                                {parse(t(`subscription.${type}.size.${sizeOption}`))}
                                            </div>
                                        </div>
                                        <div className="b-price">
                                            <span className="amount">
                                                <div className="amount"><Price value={getPrice("","",sizeOption)} withCurrency={1} /></div>
                                            </span>
                                        </div>
                                    </div>
                                </label>
                                </div>
                            ))}
                            </div>
                        </div>

                        <div className="container py-4">
                            <div className="row">
                                <div className="col primary-title">
                                    <h2><W>subscription.period.title</W></h2>
                                    <p><W>subscription.period.subtitle</W></p>
                                </div>
                            </div>

                            <div className="row justify-content-center">
                                {['4_week', '6_week', '2_month'].map(periodOption => {
                                    let [amount, unit] = periodOption.split("_");
                                    // Check if the periodOption is "2_month" and update the display
                                    if (periodOption === '2_month') {
                                        amount = '8';
                                        unit = 'week';
                                    }                                
                                    return (
                                        <div className="col-6 col-sm-4" key={periodOption}>
                                            <label className="subscription__select">
                                                <input type="radio" name="period" value={periodOption} checked={period === periodOption} onChange={handlePeriodChange} />
                                                <div className="checkbox">
                                                    <span></span>
                                                </div>
                                                <div className="body">
                                                    <div className="title">
                                                        <span className="subtitle"><W>subscription.each</W></span>
                                                        <h3 className="heading">{amount} {<W>subscription.{unit}</W>}</h3>
                                                    </div>
                                                    <div className="s-price">
                                                        <span className="before"><W>subscription.only</W></span>
                                                        <span className="amount"><Price value={getPrice(amount,unit)} withCurrency={1} /></span>
                                                        <span className="after"><W>subscription.perweek</W></span>
                                                    </div>
                                                </div>
                                            </label>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="subscription__cta">
                <button className="btn btn--red" onClick={handleSubmit}>{t('subscription.'+ type +'.cta')}</button>
            </div>

        </div>
    );
}

export default flatstore.connect(['subscription'])(SubscriptionSize);